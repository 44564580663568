import React from "react";
import SEO from "../components/seo";
import ErrorSec from "containers/Error";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <div>
        <SEO title="404" />
        <ErrorSec />
      </div>
    )
  );
};

export default NotFoundPage;
